import React, { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import layoutThree from "../components/layout/layoutThree";
import HeadBreadCrumb from "../components/Checkout/HeadBreadCrumb";
import ProductDetail from "../components/Checkout/ProductDetail";
import BillDetails from "../components/Checkout/BillDetails";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getBillingAccount } from "../redux/slices/Licenses/getBillingAccountSlice";
import { getPlanPrice } from "../redux/slices/Licenses/getPlanPriceSlice";
import EcnButton from "../components/StyledComponents/EcnButton";
import { Dialog, DialogContent } from "@mui/material";
import Lottie from "react-lottie";

import paymentSuccess from "../assets/lottie/payment_success.json";
import paymentError from "../assets/lottie/payment_error.json";
import { resetPurchasePlan } from "../redux/slices/Billing/purchasePlanSlice";
import { resetLicensePurchase } from "../redux/slices/Licenses/requestLicenseSlice";
import { recruitRedirect } from "../helpers/TempData";

const API_GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE || "";
const Checkout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const plan = searchParams.get("product_id") || "";
  const payment_strategy = searchParams.get("payment_strategy") || "";
  const licenses_count = searchParams.get("licenses_count") || "";
  const plan_name = searchParams.get("plan_name") || "";
  const quantity = searchParams.get("quantity") || "";
  const credit_unit = searchParams.get("credit_unit") || "";

  const { addCardData } = useAppSelector((state) => state.addCard);
  const { updateBill } = useAppSelector((state) => state.updateBillingAccount);
  const { error: billError } = useAppSelector(
    (state: any) => state.billingCardAccount
  );
  const { error } = useAppSelector((state) => state.getPlanPrice);
  const { purchasePlanData, error: planError } = useAppSelector(
    (state) => state.purchasePlan
  );
  const { request, error: licenseError } = useAppSelector(
    (state) => state.licenseRequest
  );

  const [selectedCard, setSelectedCard] = useState("");

  useEffect(() => {
    dispatch(
      getPlanPrice({
        plan,
        payment_strategy,
        licenses_count: Number(licenses_count),
        credit_lots: Number(quantity) / Number(credit_unit),
      })
    );
  }, []);

  useEffect(() => {
    dispatch(getBillingAccount());
  }, [addCardData, updateBill]);

  const dialogCloseHandler = () => {
    dispatch(resetPurchasePlan());
    dispatch(resetLicensePurchase());
    window.location.href = `${recruitRedirect(API_GATEWAY_BASE)}dashboard`;
  };

  if (error || billError) {
    return (
      <div className="checkout-error">
        <img
          src="https://s1.ecnstatic.com/ecn/images/enterprise/page_not_found.png"
          alt="Not Found"
        />
        <p>Page not found!</p>
        <EcnButton
          variant="outlined"
          color="info"
          className="btn-outlined home-btn"
          onClick={() => navigate("/dashboard")}
        >
          Home
        </EcnButton>
      </div>
    );
  }

  return (
    <div className="checkout">
      <HeadBreadCrumb />
      <div className="body">
        <BillDetails selectedCard={selectedCard} setSelectedCard={setSelectedCard} />

        <ProductDetail
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
        />
      </div>

      <Dialog
        open={purchasePlanData || request ? true : false}
        onClose={dialogCloseHandler}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            boxShadow: "-17px 4px 30px 0px rgba(33, 33, 33, 0.10)",
            width: "419px",
            textAlign: "center",
          },
        }}
      >
        <DialogContent>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: paymentSuccess,
            }}
            height={250}
            width={250}
          />
          <p style={{ fontWeight: "500", color: "#212121" }}>Payment successful!</p>
        </DialogContent>
      </Dialog>

      <Dialog
        open={licenseError || planError ? true : false}
        onClose={dialogCloseHandler}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            boxShadow: "-17px 4px 30px 0px rgba(33, 33, 33, 0.10)",
            width: "419px",
            textAlign: "center",
          },
        }}
      >
        <DialogContent>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: paymentError,
            }}
            height={250}
            width={250}
          />
          <p style={{ fontWeight: "500", color: "#212121" }}>Payment failed!</p>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default layoutThree(Checkout);
