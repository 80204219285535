import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  LinearProgressProps,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EcnButton from "../StyledComponents/EcnButton";
import { inviteUser } from "../../redux/slices/InviteUserFlow/inviteUserSlices";
import EcnCheckboxV2 from "../StyledComponents/V2/EcnCheckboxV2";
import { Icon } from "@iconify/react";
import PreviewList from "./PreviewList";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: "40px" }}>
      <Box sx={{ width: '90%', mr: 1, }}>
        <LinearProgress variant="determinate" {...props} style={{ height: "15px" }} />
      </Box>
      <Box sx={{ minWidth: 50 }}>
        <Typography
          variant="body2"
          sx={{ color: "#212121", fontSize: "18px" }}
        >{`${Math.round(props.value / 10)}/ 10`}</Typography>
      </Box>
    </Box>
  );
}

const Preview: React.FC<{ userData: any; setActive: any, setUserData: any }> = ({
  setUserData,
  userData,
  setActive,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();



  const { inviteUserData } = useAppSelector(
    (state: any) => state.inviteUsersData
  );

  const [clickedEmails, setClickedEmails] = useState<string[]>([]);
  const [progress, setProgress] = React.useState(10);


  useEffect(() => {
    if (inviteUserData) {
      navigate("/users?view=invite-request");
      window.location.reload();
    }
  }, [inviteUserData]);

  const inviteUserHadler = () => {
    const usersToInvite = userData?.filter((user: any) =>
      clickedEmails?.includes(user.email)
    );
    dispatch(inviteUser(usersToInvite));

  };

  const backHandler = () => {
    setActive((pre: any) => pre - 1);
    navigate("?view=invite-user&step=permission");

  };

  return (
    <div className="preview">
      <div className="top">
        <Box sx={{ width: '100%' }}>
          {clickedEmails?.length != 0 && <p>{clickedEmails?.length} Access Assigned</p>}
          <LinearProgressWithLabel value={clickedEmails?.length * 10} />
        </Box>
        <h3 className="review-title">Review Invite</h3>
        <p className="para">Review your invited users and their assigned roles one final time before sending the invitations.</p>


        <div className="preview-table">
          <div className="table-heading">
            <div className="table-heading-left">
              {/* <EcnCheckboxV2 /> */}
              <p>Users</p>

            </div>
            <div className="table-heading-right">
              <p >Roles</p>
            </div>

          </div>

        </div>
        <div className="list-user">
          {userData?.map((item: any) => (
            <PreviewList item={item} setUserData={setUserData} userData={userData} setClickedEmails={setClickedEmails} clickedEmails={clickedEmails} />
          ))}
        </div>


      </div>
      <div className="bottom">
        <div className="left">
          <EcnButton
            variant="outlined"
            color="secondary"
            className="btn-outlined"
            onClick={backHandler}
          >
            Back
          </EcnButton>
          <EcnButton
            className="btn-text"
            color="secondary"
            onClick={() => navigate("/users?view=all-users")}
          >
            Cancel
          </EcnButton>
        </div>
        <EcnButton
          variant="contained"
          className="btn-contained"
          onClick={inviteUserHadler}
          disabled={clickedEmails.length === 0}
        >
          Send invite
        </EcnButton>
      </div>
    </div>
  );
};

export default Preview;
