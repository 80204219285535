import { Icon } from "@iconify/react";
import { useAppSelector } from "../../../redux/hooks";

const CreditsInfo = ({ creditsHome }: { creditsHome: any }) => {
  const { userInfo } = useAppSelector((state) => state.userDetails);
  return (
    <div className="credits-info">
      <div className="available">
        <div className="data-title">
          <Icon icon="lucide:ticket-check" className="icon" />
          Available Credits
        </div>
        <div className="available-number">
          {creditsHome?.total_available_credits}
          <span>/{creditsHome?.total_credits}</span>
        </div>
      </div>
      <div className="plan-credit">
        <div className="data-title">
          <Icon icon="bx:planet" className="icon" />
          Your Plan Credits
        </div>
        <span>{creditsHome?.total_credits}</span> credits/{userInfo?.paid ? "mo" : "year"}
      </div>
    </div>
  );
};
export default CreditsInfo;
