import cancelInviteSlice from "./cancelInviteSlice";
import getAllMemberSlice from "./getAllMemberSlice";
import getMemberSlice from "./getMemberSlice";
import inviteUserSlices from "./inviteUserSlices";
import getLocationSlices from "./getLocationSlice";


export const allInviteSlices = {
  getMember: getMemberSlice,
  inviteUsersData: inviteUserSlices,
  getAllMember: getAllMemberSlice,
  cancelInvite: cancelInviteSlice,
  locationList: getLocationSlices,
}
