import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

// Thunk to handle sign-up
export const postSignUp = createAsyncThunk(
  "postSignUp",
  async (emailData: any, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${GATEWAY_BASE + AUTH_BASE}/v1/keycloak/signup`,
        emailData
      );
      return data;
    } catch (error: any) {
      console.error("Signup Error:", error);

      // Return server error details
      if (error.response && error.response.data) {
        return rejectWithValue({
          message: error.response.data.message || "An error occurred",
          status: error.response.status,
        });
      }

      // Fallback for unexpected errors
      return rejectWithValue({
        message: "Network error or unexpected issue occurred",
        status: "NETWORK_ERROR",
      });
    }
  }
);

// Slice to handle state
const postSignUpSlice = createSlice({
  name: "postSignUp",
  initialState: {
    loading: false,
    success: false,
    postSignUpData: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postSignUp.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        state.errorData = null; // Reset error state
      })
      .addCase(postSignUp.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.postSignUpData = action.payload;
      })
      .addCase(postSignUp.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.errorData = action.payload; // Capture custom error data
      });
  },
});

export default postSignUpSlice.reducer;
