import React, { FC, useEffect, useRef, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { Icon } from "@iconify/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { useAppSelector } from "../../redux/hooks";
import { trackMixpanelEvents } from "../../helpers/Analytics/mixpanelProxyApi";
import EcnCheckboxFilled from "../StyledComponents/V2/EcnCheckboxFilled";

const BottomHeader: FC<{
  setFilters: any;
  downloadActive: any;
  setRowsInvite: any;
  setRowsUser: any;
  rowsAllUser: any;
  rowsAllInvite: any;
  memberData: any;
}> = ({
  setFilters,
  downloadActive,
  setRowsInvite,
  setRowsUser,
  rowsAllUser,
  rowsAllInvite,
  memberData,
}) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const view = searchParams.get("view");
    const selectRef: any = useRef(null);
    const searchContainerRef = useRef<HTMLDivElement | null>(null);

    const { licenseList } = useAppSelector((state: any) => state.getLicenseLists);
    const { userInfo } = useAppSelector((state: any) => state.userDetails);

    const { roles } = useAppSelector((state) => state.enterpriseRole);

    const [selectedLicenses, setSelectedLicenses] = React.useState<any[]>([]);
    const [activeSearch, setActiveSearch] = useState<boolean>(false);
    const [click, setClick] = useState<boolean>(false);
    const [selectedRoles, setSelectedRoles] = React.useState<any[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [searchText, setSearchText] = useState<any>("");
    const [hideIcon, setHideIcon] = useState<boolean>(false);

    useEffect(() => {
      setFilters([]);
      setSelectedLicenses([]);
      setSelectedRoles([]);
      setSearchText("");
      setClick(false);
    }, [view]);

    useEffect(() => {
      if (memberData?.total_count <= 1000 && click) {
        if (view === "all-users") {
          setRowsUser(
            rowsAllUser?.filter((filter: any) => {
              const rowContainslicence =
                selectedLicenses?.some((licence: any) => {
                  return (
                    filter?.licenses?.filter((item: any) => item?.plan_id === licence)
                      .length > 0
                  );
                }) || selectedLicenses?.length === 0;
              const rowContainsRoles =
                selectedRoles?.some((role: any) => filter?.roles?.includes(role)) ||
                selectedRoles?.length === 0;
              return rowContainsRoles && rowContainslicence;
            })
          );
        } else {
          setRowsInvite(
            rowsAllInvite?.filter((filter: any) => {
              return (
                (selectedLicenses?.some((licence: any) =>
                  filter?.licenses?.plan_id?.includes(licence)
                ) ||
                  selectedLicenses?.length === 0) &&
                (selectedRoles?.some((role: any) => filter?.roles?.includes(role)) ||
                  selectedRoles?.length === 0)
              );
            })
          );
        }
      }
    }, [selectedLicenses, memberData, selectedRoles]);

    // Click outside logic with TypeScript
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          searchContainerRef.current &&
          !searchContainerRef.current.contains(event.target as Node) &&
          !searchText
        ) {
          setActiveSearch(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [searchContainerRef, searchText]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      trackMixpanelEvents({
        eventName: "ENTERPRISE_ADMIN_MEMBERS_FILTER",
        url: window.location.href,
        distinctId: userInfo ? userInfo.email : "",
        properties: {},
      });
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const inviteUserHandler = () => {
      navigate("?view=invite-user");
    };

    const licenseHandler = (id: string) => {
      if (selectedLicenses.find((license: any) => license === id)) {
        setSelectedLicenses((prev) => prev.filter((license) => license !== id));
        if (memberData?.total_count > 1000) {
          setFilters((prev: any) => prev.filter((filter: any) => filter.plan !== id));
        }
      } else {
        setSelectedLicenses((prev) => [...prev, id]);
        if (memberData?.total_count > 1000) {
          setFilters((prev: any) => [...prev, { type: "plan", plan: id }]);
        }
      }
      setClick(true);
    };

    const roleHandler = (name: any) => {
      if (selectedRoles.find((role: any) => role === name)) {
        setSelectedRoles((prev) => prev.filter((role) => role !== name));
        if (memberData?.total_count > 1000) {
          setFilters((prev: any) =>
            prev.filter((filter: any) => filter.role !== name)
          );
        }
      } else {
        setSelectedRoles((prev) => [...prev, name]);
        if (memberData?.total_count > 1000) {
          setFilters((prev: any) => [...prev, { type: "role", role: name }]);
        }
      }
      setClick(true);
    };

    const searchHandler = (e: any) => {
      trackMixpanelEvents({
        eventName: "ENTERPRISE_ADMIN_MEMBERS_SEARCH",
        url: window.location.href,
        distinctId: userInfo ? userInfo.email : "",
        properties: {},
      });
      setSearchText(e.target.value);
      setFilters((prev: any) =>
        prev.filter((filter: any) => filter.type !== "search")
      );
      setFilters((prev: any) => [...prev, { type: "search", query: e.target.value }]);
    };

    const activeSearchHandler = () => {
      setActiveSearch(true);
    };

    const clearSearchHandler = () => {
      setSearchText("");
      setFilters((prev: any) =>
        prev.filter((filter: any) => filter.type !== "search")
      );
    };

    return (
      <div className="bottom-header">
        <div className="wrapper">
          <div className="left">
            <div
              className={view === "all-users" ? "all-users active" : "all-users"}
              onClick={() => navigate("?view=all-users")}
            >
              <h3>All Users </h3>
              <p className="users-count">
                (
                {
                  memberData?.result?.filter(
                    (filter: any) => filter.signup_completed === true
                  )?.length
                }{" "}
                users)
              </p>
            </div>
            <div
              className={view === "invite-request" ? "all-users active" : "all-users"}
              onClick={() => navigate("?view=invite-request")}
            >
              <h3>Invitations</h3>
              <p className="users-count">
                ({memberData?.total_count ? memberData?.total_count - 1 : ""} invites)
              </p>
            </div>
          </div>
          <div
            className={
              downloadActive ? "bottom-right download-active" : "bottom-right"
            }
          >
            {activeSearch ? (
              <div
                ref={searchContainerRef}
                className={hideIcon ? "hide-icon active-search" : "active-search"}
              >
                <Icon
                  icon="lucide:search"
                  width={24}
                  height={24}
                  color="rgba(33, 33, 33, 0.6)"
                  className="icon"
                />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  onChange={searchHandler}
                  ref={selectRef}
                />
                {searchText !== "" && (
                  <Icon
                    icon="ion:close-outline"
                    className="search-cross"
                    onClick={clearSearchHandler}
                  />
                )}
              </div>
            ) : (
              <div className="search" onClick={activeSearchHandler}>
                <Icon
                  icon="lucide:search"
                  width={24}
                  height={24}
                  color="rgba(33, 33, 33, 0.6)"
                  className="icon"
                />
              </div>
            )}

            <div>
              <Button
                id="positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                variant="outlined"
                className={activeSearch ? "filter active" : "filter"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 21"
                  fill="none"
                  style={{ marginRight: "-5px" }}
                >
                  <path
                    d="M18.3327 2.5H1.66602L8.33268 10.3833V15.8333L11.666 17.5V10.3833L18.3327 2.5Z"
                    stroke="#212121"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {!activeSearch && "Filter"}
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                className="menu-container-users"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={() => { }}>
                  <Accordion disableGutters>
                    <AccordionSummary
                      expandIcon={<Icon icon="ic:outline-arrow-forward-ios" />}
                    >
                      Licenses
                    </AccordionSummary>
                    <AccordionDetails>
                      {licenseList && licenseList?.map((item: any) => (
                        <div className="option">
                          <EcnCheckboxFilled
                            checked={selectedLicenses?.includes(item?.id)}
                            onChange={() => licenseHandler(item?.id)}
                          />
                          <p>{item?.name}</p>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </MenuItem>
                <MenuItem>
                  <Accordion disableGutters>
                    <AccordionSummary
                      expandIcon={<Icon icon="ic:outline-arrow-forward-ios" />}
                    >
                      Roles
                    </AccordionSummary>
                    <AccordionDetails>
                      {roles?.map((item: any) => (
                        <div className="option">
                          <EcnCheckboxFilled
                            onChange={() => roleHandler(item)}
                            checked={selectedRoles?.includes(item)}
                          />
                          <p style={{ textTransform: "capitalize" }}>
                            {item.split("-").join(" ")}
                          </p>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </MenuItem>
              </Menu>
            </div>

            {view !== "invite-user" && (
              <Button
                variant="outlined"
                className={activeSearch ? "btn-outlined active" : "btn-outlined"}
                onClick={inviteUserHandler}
              >
                <Icon
                  icon="lucide:user-round-plus"
                  width={20}
                  height={20}
                  className="icon"
                />
                {!activeSearch && "Invite users"}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

export default BottomHeader;
