import React, { useEffect } from 'react'
import layoutSignup from '../../layout/layoutSignup'

import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { freePlanPurchase } from '../../../redux/slices/Payment/freePlanPurchaseSlice';
import { assignRoleAndLicense } from '../../../redux/slices/Billing/assignRoleAndLicenseSlice';
import { getBillingAccount } from '../../../redux/slices/Licenses/getBillingAccountSlice';
import { recruitRedirect } from '../../../helpers/TempData';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || "";
const API_GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE || "";
interface AddCardDrawerProps {
    isDefault?: boolean;
}
const appearance: StripeElementsOptions["appearance"] = {
    theme: "stripe",
    variables: {
        fontFamily: "Poppins, Open Sans,sans-serif",
        colorText: "#212121",
        colorDanger: "#ba0000",
        colorPrimary: "#0034bb",
        borderRadius: "8px",
    },
};

const options: StripeElementsOptions = {
    appearance,
};
const stripePromise = loadStripe(STRIPE_KEY);

const Checkout = ({ isDefault }: AddCardDrawerProps) => {
    const dispatch = useAppDispatch();

    const { accountInfos } = useAppSelector((state) => state.getAccountInfoData);
    const { userInfo } = useAppSelector((state) => state.userDetails);
    const { billAccount } = useAppSelector((state: any) => state.billingCardAccount);
    const { freePlanData } = useAppSelector(
        (state) => state.freePlan
    );

    useEffect(() => {
        dispatch(getBillingAccount());
    }, []);

    useEffect(() => {

        if (userInfo && userInfo?.licenses?.length === 0) {
            dispatch(freePlanPurchase({ "plan_id": "enterprise-recruiter-plan-free" }))
        }
    }, [userInfo])

    useEffect(() => {
        if (billAccount && billAccount?.payment_methods?.length != 0 && billAccount.message != "billing-details-not-found") {
            window.location.href = `${recruitRedirect(API_GATEWAY_BASE)}dashboard`;
        }

    }, [billAccount])

    useEffect(() => {
        if (freePlanData) {
            setTimeout(() => {
                dispatch(
                    assignRoleAndLicense({
                        license: "enterprise-recruiter-plan-free",
                        assign_roles: true,
                        assign_licenses: true,
                        user_ids: [userInfo?.id],
                        roles: ["enterprise-admin"],
                    })
                );
            }, 2000);
        }

    }, [freePlanData])

    return (
        <div>
            <div style={{ width: "50%", margin: "auto", textAlign: "center" }}>
                <h3 style={{ color: "#212121", fontSize: "20px", fontWeight: "600", marginBottom: "10px" }}>Enter Payment information</h3>
                <p style={{ fontSize: "16px", fontWeight: "600", color: "#838383", marginBottom: "20px" }}>You’re on our <span style={{ color: "#0034BB" }}> Free Plan </span>  so there’s no charge! We just need a few details to verify your identity.</p>
            </div>
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm isDefault={isDefault || false} />
            </Elements>
        </div >
    )
}

export default layoutSignup(Checkout)