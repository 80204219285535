import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const userSocialLogin = createAsyncThunk(
  "userSocialLogin",
  async (session_id: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + AUTH_BASE}/v1/oauth/login`,
      {
        session_id: session_id,
      }
    );
    return data;
  }
);

const userSocialLoginSlice = createSlice({
  name: "userSocialLogin",
  initialState: {
    loading: false,
    success: false,
    login: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userSocialLogin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(userSocialLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.login = action.payload;
    });
    builder.addCase(userSocialLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default userSocialLoginSlice.reducer;
