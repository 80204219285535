import { useEffect } from "react";
import { useAppDispatch } from "../../redux/hooks";
import Header from "../Nav/SignupHeader/Header";



export default function layoutSignup(Component: any) {
  function layoutSignup(props: any) {



    return (
      <div className="layout-signup">
        <div className="content">
          <div className="page">
            <div className="bg_circle-wrapper">
              <div className="circle circle-one" />
              <div className="circle circle-two" />
              <div className="circle circle-three" />
            </div>
            <div className="page-content">
              <Header />
              <Component {...props} />
              {/* <Footer /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return layoutSignup;
}
