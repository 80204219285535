// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "../../axiosInterceptor";

// const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
// const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

// export const freePlanPurchase = createAsyncThunk(
//     "freePlanPurchase",
//     async (passwordDetails: any) => {
//         const { data } = await axios.post(
//             `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/purchase_free_plan`,
//             passwordDetails
//         );
//         return data;
//     }
// );

// const freePlanPurchaseSlice = createSlice({
//     name: "freePlanPurchase",
//     initialState: {
//         loading: false,
//         freePlanData: null as any,
//         error: false,
//     },
//     reducers: {
//         reset: (state) => {
//             state.loading = false;
//             state.freePlanData = null;
//             state.error = false;
//         },
//     },
//     extraReducers: (builder) => {
//         builder.addCase(freePlanPurchase.pending, (state, action) => {
//             state.loading = true;
//         });
//         builder.addCase(freePlanPurchase.fulfilled, (state, action) => {
//             state.loading = false;
//             state.freePlanData = action.payload;
//         });
//         builder.addCase(freePlanPurchase.rejected, (state, action) => {
//             state.loading = false;
//             state.error = true;
//         });
//     },
// });
// export const { reset } = freePlanPurchaseSlice.actions;
// export default freePlanPurchaseSlice.reducer;


import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENT_LICENSE_BASE = process.env.REACT_APP_PAYMENT_LICENSE_BASE || "";

export const freePlanPurchase = createAsyncThunk("freePlanPurchase", async (cardDetails: any) => {
    const { data } = await axios.post(
        `${GATEWAY_BASE + PAYMENT_LICENSE_BASE}/v1/purchase_free_plan`,
        cardDetails
    );
    return data;
});

const freePlanPurchaseSlice = createSlice({
    name: "freePlanPurchase",
    initialState: {
        loading: false,
        freePlanData: null,
        error: false,
    },
    reducers: {
        resetAddCard: (state) => {
            state.loading = false;
            state.freePlanData = null;
            state.error = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(freePlanPurchase.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(freePlanPurchase.fulfilled, (state, action) => {
            state.loading = false;
            state.freePlanData = action.payload;
        });
        builder.addCase(freePlanPurchase.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });
    },
});

export const { resetAddCard } = freePlanPurchaseSlice.actions;

export default freePlanPurchaseSlice.reducer;
