import React, { useEffect, useState } from 'react'
import layoutSignup from '../components/layout/layoutSignup'
import EcnInput from '../components/StyledComponents/V2/EcnInput'
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import { Icon } from '@iconify/react';
import EcnButton from '../components/StyledComponents/EcnButton';
import { Button, Dialog, MenuItem } from '@mui/material';
import OTPInput from "react-otp-input";
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { postSignUp } from '../redux/slices/userSlices/signUpSlice';
import { verifyOtp } from '../redux/slices/userSlices/verifyOtpSlice';
import { correctEmail } from '../helpers/Validations/BasicValidations';
import EcnSelect from '../components/StyledComponents/EcnSelect';
import { userBaseDetail } from '../redux/slices/userSlices/userBaseDetailSlice';

import { userSignin } from '../redux/slices/userSlices/userSigninSlice';
import { userDetails } from '../redux/slices/userSlices/userDetailsSlice';
import { assignRoleAndLicense } from '../redux/slices/Billing/assignRoleAndLicenseSlice';
import { freePlanPurchase } from '../redux/slices/Payment/freePlanPurchaseSlice';
import { googleAndMicrosoftRedirect, loginToAuth, recruitRedirect, signUpAuth } from '../helpers/TempData';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { userSocialLogin } from '../redux/slices/userSlices/userSocialLoginSlice';


const API_GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE || "";

const Signup = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    const view = searchParams.get("view") || "";
    const error_type = searchParams?.get("error") || "";
    const session_id = searchParams?.get("session_id") || "";
    const state_type = searchParams?.get("state") || "";




    const { verifyOtpData, error: verifyError } = useAppSelector(
        (state) => state.verifyOtps
    );

    const { postSignUpData, errorData } = useAppSelector(
        (state) => state.signUpData
    );

    const { signupInfo, loading } = useAppSelector(
        (state) => state.userBaseDetail
    );

    const { freePlanData } = useAppSelector(
        (state) => state.freePlan
    );
    const { token } = useAppSelector(
        (state) => state.userSignIn
    );

    const { userInfo, error: userInfoError } = useAppSelector(
        (state) => state.userDetails,
    );

    const { accountInfos } = useAppSelector((state) => state.getAccountInfoData);

    const { login, success } = useAppSelector(
        (state) => state.userSocialLoginData
    );


    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<any>(null);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [step, setStep] = useState(0);
    const [isError, setIsError] = useState(false);
    const [click, setClick] = useState(false)
    const [clickOtp, setClickOpt] = useState(false)
    const [resendTime, setResendTime] = useState<number>(60);
    const [errorMessage, setErrorMessageMessage] = useState("");
    const [open, setOpen] = React.useState(false);
    const [isVerify, setIsVerify] = useState(false)
    const [basicDetailClick, setBasicDetailClick] = useState(false);
    const [b2cUser, setB2cUser] = useState(false);
    const [isSignIn, setIsSignIn] = useState(false);
    const [notVerify, setNotVerify] = useState(false);
    const [isGoogleSignup, setGoogleSignup] = useState(false);

    const [data, setData] = useState<any>({
        first_name: "",
        last_name: "",
        company: "",
        company_size: "",
    })
    useEffect(() => {
        generateRandomPassword(8); // Generate password on mount
    }, []);

    useEffect(() => {
        if (postSignUpData && click) {
            setStep(1);

            let intervalId: any;
            if (resendTime > 0) {
                intervalId = setInterval(() => {
                    setResendTime((prev) => (prev === 0 ? 0 : prev - 1));
                }, 1000);
            }

            return () => {
                if (intervalId) {
                    clearInterval(intervalId);
                }
            };
        }
    }, [postSignUpData, click]);


    useEffect(() => {
        if (verifyOtpData && verifyOtpData?.message != "Request failed with status code 400" && clickOtp) {
            setClickOpt(false);
            setIsVerify(true);
            setErrorMessageMessage("");

            setTimeout(
                () => {
                    setStep(0);
                },
                2000
            );
        }
    }, [verifyOtpData, clickOtp]);


    useEffect(() => {
        if (verifyOtpData && verifyOtpData?.message === "Request failed with status code 400" && clickOtp) {
            setClickOpt(false);
            setIsVerify(false);
            setNotVerify(true)
        }
    }, [verifyOtpData, clickOtp]);

    useEffect(() => {
        if (errorData?.status === 400 && click) {
            setClick(false);
            let newText = formatMessage(errorData?.message)
            setErrorMessageMessage(`${newText}`);
            setStep(0);
            setClickOpt(false);

        }
    }, [errorData, click]);



    useEffect(() => {
        if (signupInfo && basicDetailClick) {
            setIsSignIn(true);
            dispatch(userSignin({ email: email, password: password }));
        }
    }, [signupInfo, basicDetailClick]);

    useEffect(() => {
        if (signupInfo && b2cUser) {
            navigate("/signup?view=true");
        }
    }, [signupInfo, b2cUser]);



    useEffect(() => {
        if (token && isSignIn) {
            dispatch(userDetails());
            navigate("/signup?view=true");
        }
    }, [token]);

    useEffect(() => {
        if (view === "true" && userInfo) {
            setOpen(true);
        }

    }, [userInfo, view])


    useEffect(() => {
        if (userInfo) {
            setData({
                ...data,
                first_name: userInfo?.first_name,
                last_name: userInfo?.last_name,
            });
            setEmail(userInfo?.email);
            setIsValidEmail(true);
            setIsVerify(true);
        }
    }, [userInfo]);

    useEffect(() => {
        if (session_id != "") {
            setGoogleSignup(true)
            dispatch(userSocialLogin(session_id));
        }
    }, [session_id]);

    useEffect(() => {
        if (login && state_type != "" && isGoogleSignup) {
            if (state_type === "b2b_signup") {
                dispatch(userDetails());
                setGoogleSignup(false)

            }
        }
    }, [success, state_type]);



    useEffect(() => {
        if (accountInfos && userInfoError) {
            setEmail(accountInfos?.email);
            setIsValidEmail(true);
            setIsVerify(true);
        }
    }, [accountInfos]);


    // useEffect(() => {
    //     if (userInfo && accountInfos?.basic_details_filled) {
    //         window.location.href = `${recruitRedirect(API_GATEWAY_BASE)}dashboard`;
    //     }

    // }, [userInfo])



    const formatMessage = (message: string) => {
        return message.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const emailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let isValid = true;
        const emailValue = e.target.value;
        setEmail(emailValue);
        setErrorMessageMessage("")

        const domain = emailValue.split("@")[1];

        // List of disallowed domains
        const disallowedDomains = [".edu", ".ac"];

        const isUniversityEmail = disallowedDomains.some((ext) =>
            domain?.endsWith(ext)
        );

        if (isUniversityEmail) {
            setError(true);
        } else {
            setError(false);
        }


        const correctEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        // Validate email format
        if (correctEmail.test(emailValue) && !isUniversityEmail) {
            isValid = true;
        } else {
            isValid = false;
        }

        setIsValidEmail(isValid);
    };


    const generateRandomPassword = (length: any) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const specialCharacters = '!@#$%^&*()_+[]{}|;:,.<>?';

        const allCharacters = characters + specialCharacters;
        let password = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * allCharacters.length);
            password += allCharacters[randomIndex];
        }

        setPassword(password);
    }

    const sendOtpHandler = () => {
        setClick(true)
        dispatch(
            postSignUp({
                email: email,
                password: password,
                enterprise: true,
            })
        );


    }

    const otpChangeHandler = (otp: any) => {
        setIsError(false);
        const upOtp = otp.toUpperCase();
        setOtp(upOtp);
        setClickOpt(true)

        console.log("upOtp", upOtp.length)
        if (upOtp?.length === 6) {
            dispatch(
                verifyOtp({
                    email: email,
                    otp: upOtp,
                    session_id: postSignUpData?.session_id,
                })
            );
        }

    };


    const inputHandler = (e: any, type: string) => {
        setData({ ...data, [type]: e.target.value })

    }

    const changeHandler = (e: any) => {
        setData({ ...data, company_size: e.target.value })
    }

    const nextHandler = () => {
        if (userInfo) {
            setB2cUser(true)
            dispatch(userBaseDetail({ ...data, email: email, temp_password: password, terms_accepted: true }));
        }
        else {
            setBasicDetailClick(true)
            dispatch(userBaseDetail({ ...data, email: email, temp_password: password, terms_accepted: true }));
        }

    }


    const handleClose = () => {
        navigate("/card-details")
    };

    const loginHandler = () => {
        window.location.href = `${loginToAuth(API_GATEWAY_BASE)}`;

    }

    const switchHandler = () => {
        window.location.href = `${signUpAuth(API_GATEWAY_BASE)}`;
    }



    console.log("userInfo", verifyOtpData)

    return (
        <div className='signup-flow'>
            <div className='signup-card'>
                <h1 className='title'>Create Firm Account</h1>
                <div className="row">
                    <div className="name-field ">
                        <div className='left-row'>
                            <label htmlFor="">First Name*</label>
                            <EcnInput
                                value={data?.first_name}
                                placeholder="Enter First name  "
                                className={`ecn-input signin-input`}
                                onChange={(e) => inputHandler(e, "first_name")}
                            />
                        </div>
                        <div className='right-row'>
                            <label htmlFor="">Last Name*</label>
                            <EcnInput
                                value={data?.last_name}
                                placeholder="Enter Last name  "
                                className={`ecn-input signin-input`}
                                onChange={(e) => inputHandler(e, "last_name")}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    {step === 0 ?
                        <div className="field">
                            <label htmlFor="">Email*</label>
                            <div className='email-verify-button'>
                                <EcnInput
                                    placeholder="Enter email address"
                                    className={`ecn-input signin-input ${errorData?.status === 400 || error ? "error-state" : ""
                                        }`}

                                    value={email}
                                    onChange={emailHandler}
                                    disabled={isVerify}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon
                                                    icon="lucide:mail"
                                                    height={20}
                                                    width={20}
                                                    color="black"
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <EcnButton className='button' variant="contained" onClick={sendOtpHandler} disabled={(!isValidEmail || email === "" || isVerify)}>Send OTP</EcnButton>
                            </div>

                            {!isValidEmail && email != "" && (
                                <p style={{ color: "rgba(186, 0, 0, 1)", marginTop: "-3px", fontSize: "12px" }}>
                                    Invalid email address
                                </p>
                            )}
                            {!isVerify && errorMessage != "" && (
                                <p
                                    style={{
                                        color: "rgba(186, 0, 0, 1)",
                                        marginTop: "-2px",
                                        marginBottom: "4px",
                                        fontSize: "12px"
                                    }}
                                >
                                    {errorMessage}
                                </p>
                            )}
                        </div>
                        :

                        <div className='otp'>
                            <div className='top'>
                                <div className='icon-text' onClick={() => setStep(0)}>
                                    <Icon icon="lucide:chevron-left" />
                                    <p>Enter the code sent to your email.</p>
                                </div>

                                {/* <span>Resend</span> */}
                                {resendTime > 0 ? (
                                    <span>Resend in {resendTime}s</span>
                                ) : (
                                    <Button onClick={sendOtpHandler} disabled={loading}>Resend</Button>
                                )}

                            </div>
                            <div className="otp-input">
                                <OTPInput
                                    value={otp}
                                    onChange={(val) => otpChangeHandler(val)}
                                    numInputs={6}
                                    renderInput={(props, index) => (
                                        <input
                                            {...props}
                                            className={`otp-input ${otp.length > index ? "data-entered" : ""
                                                }`}
                                        />
                                    )}
                                    containerStyle="otp-container"
                                    inputStyle={
                                        verifyError
                                            ? {
                                                background: "transparent",
                                                border: "1px solid #ba0000",
                                                fontSize: "1.5rem",
                                            }
                                            : isVerify
                                                ? {
                                                    background: "transparent",
                                                    border: "1px solid #027A48",
                                                    fontSize: "1.5rem",
                                                }
                                                : {
                                                    fontSize: "1.5rem",
                                                }
                                    }
                                />


                            </div>
                        </div>

                    }
                    {step === 1 && !isVerify && errorMessage != "" && (
                        <p
                            style={{
                                color: "rgba(186, 0, 0, 1)",
                                marginTop: "-2px",
                                marginBottom: "4px",
                                fontSize: "12px"
                            }}
                        >
                            {errorMessage}
                        </p>
                    )}
                    {step === 1 && isVerify && <Icon icon="lucide:check" className='icon' color='#027A48' />}
                    {step === 1 && notVerify && <Icon icon="lucide:x" className='icon' color='#BA0000' />}



                </div>

                <div className="row">
                    <label htmlFor="">Organisation Name*</label>
                    <EcnInput
                        placeholder="Enter Organisation name  "
                        className={`ecn-input signin-input`}
                        onChange={(e) => inputHandler(e, "company")}
                    />
                </div>

                <div className="row">
                    <label htmlFor="">Company size*</label>
                    <EcnSelect
                        displayEmpty
                        name="company_size"
                        className="ecn-select"
                        size="small"
                        style={{ background: "#ffffff", fontWeight: "600" }}
                        // value={adminData.company_size}
                        onChange={changeHandler}
                        renderValue={(selected: any) => {
                            if (selected === "") {
                                return <span>Select company size</span>;
                            }
                            return selected;
                        }}
                        MenuProps={{
                            MenuListProps: {
                                style: {
                                    fontWeight: '700', // Increase font weight for menu items
                                },
                            },
                        }}
                    >
                        <MenuItem value="1-49" >1-49</MenuItem>
                        <MenuItem value="50-299">50-299</MenuItem>
                        <MenuItem value="300-599">300-599</MenuItem>
                        <MenuItem value="600-999">600-999</MenuItem>
                        <MenuItem value="1000+">1000+</MenuItem>
                    </EcnSelect>
                </div>

                <EcnButton
                    className='ecn-button'
                    variant="contained"
                    disabled={data?.company === " " || data?.first_name === " " || data?.last_name === " " || data?.company_size === "" || email === "" || !isVerify}
                    onClick={nextHandler}

                >Next</EcnButton>

                {error_type != "" && (
                    <p
                        style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#ba0000",
                            textAlign: "center",
                            marginTop: "15px",

                        }}
                    >
                        {error_type?.replace('-', ' ').replace(/^./, (str) => str.toUpperCase())}
                    </p>
                )}

                {!userInfo && <div>

                    <p className='already' onClick={loginHandler}>Already have a account? <span>Log In</span> </p>

                    <div className="signup-with-google-and-microsoft">
                        <div className="con">
                            <p>Or Continue with</p>
                        </div>
                        <div className="google-micro-button">
                            <div className="google">

                                <Button onClick={() => window.open(`https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=986683004214-4j74ama78t15qvqeu4pudr2020omh6be.apps.googleusercontent.com&scope=email%20profile&access_type=offline&prompt=consent&state=b2b_signup&redirect_uri=${API_GATEWAY_BASE}/auth/v1/v1/google/signup`, "_self")} className='button' >
                                    <img src="https://s1.ecnstatic.com/ecn/images/common/Google.png" />
                                    Google
                                </Button>

                            </div>
                            <div className="google">

                                <Button onClick={() => window.open(`
https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=7dc3715c-6148-414e-a4a2-73da0f8d9419&response_type=code&redirect_uri=${API_GATEWAY_BASE}/auth/v1/v1/microsoft/signup&state=b2b_signup
                                    &response_mode=query&scope=user.read%20openid%20profile%20email`, "_self")}>

                                    <img src="https://s1.ecnstatic.com/ecn/images/common/Microsoft.png" />
                                    Microsoft
                                </Button>

                            </div>
                        </div>

                    </div>


                    <div className='switch'>
                        <p className='not'>Not firm or company?</p>
                        <p className='net' onClick={switchHandler}>Create Account For Networking</p>

                    </div>

                </div>}

            </div>




            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="dialog-success"
                PaperProps={{ sx: { borderRadius: "12px" } }}
            >
                <div className="img">
                    <img src="https://ecn.blob.core.windows.net/ecn/images/Animation - 1733162269661.gif" alt="" />
                </div>
                <div className="text">
                    <h4>Your account has been created successfully</h4>
                    <p>Your password has been sent to your email. Please note that your functionality is limited as you are currently on the <span>Free Plan.</span></p>

                </div>
            </Dialog>
        </div >
    )
}

export default layoutSignup(Signup)