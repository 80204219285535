import layoutTwo from "../components/layout/layoutTwo";
import React, { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import TopHeader from "../components/User/TopHeader";
import BottomHeader from "../components/User/BottomHeader";
import Body from "../components/User/Body";

import InviteRequestTable from "../components/User/InviteRequestTable";
import UserDetails from "../components/User/UserDetails/UserDetails";
import { getAllMember } from "../redux/slices/InviteUserFlow/getAllMemberSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getMember } from "../redux/slices/InviteUserFlow/getMemberSlice";
import { getLicense } from "../redux/slices/Licenses/getLicenseListSlice";
import { getEnterpriseRole } from "../redux/slices/Licenses/getEnterpriseRolesSlice";
import moment from "moment";
import InviteUsers from "../components/User/InviteUsers";



const User = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const view = searchParams.get("view") || "";
  const page = searchParams.get("page");
  const pagenumber = Number(page) ? Number(page) : 1;

  const { getMemberData, loading } = useAppSelector(
    (state: any) => state.getMember
  );
  const { getAllMemberData } = useAppSelector(
    (state: any) => state.getAllMember
  );
  const { userInfo } = useAppSelector((state: any) => state.userDetails);
  const { reminderResponse, error } = useAppSelector(
    (state: any) => state.sendReminder
  );
  const { cancelInviteData } = useAppSelector(
    (state: any) => state.cancelInvite
  );
  const [downloadActive, setDownloadActive] = useState<boolean>(false);
  const [filters, setFilters] = useState<any[]>([]);
  const [getUserMemberdata, setGetUserMemberData] = useState<any>();
  const [getInviteMemberdata, setGetInviteMemberData] = useState<any>();
  const [rowsUser, setRowsUser] = useState<any[]>([]);
  const [rowsInvite, setRowsInvite] = useState<any[]>([]);
  const [rowsAllUser, setRowsAllUser] = useState<any[]>([]);
  const [rowsAllInvite, setRowsAllInvite] = useState<any[]>([]);
  const [memberData, setMemberData] = useState<any>();
  const [Loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (view === "") navigate("?view=all-users");
  }, [view]);

  useEffect(() => {
    if (!page && (view === "all-users" || filters.length === 0)) {
      dispatch(
        getMember({
          filters: [...filters, { type: "signup", signup_completed: true }],

          skip: 0,
          limit: 1000,
        })
      );
    }
  }, [filters]);

  useEffect(() => {
    dispatch(getLicense());
  }, []);

  useEffect(() => {
    dispatch(getEnterpriseRole());
  }, []);

  useEffect(() => {
    if (!page && (view === "invite-request" || filters.length === 0)) {
      dispatch(
        getAllMember({
          filters: filters,
          skip: 0,
          limit: 1000,
        })
      );
    }
  }, [reminderResponse, filters, cancelInviteData]);

  useEffect(() => {
    if (filters && view === "all-users" && page) {
      dispatch(
        getMember({
          filters: [...filters, { type: "signup", signup_completed: true }],
          skip: 1000 * (pagenumber - 1),
          limit: 1000,
        })
      );
    }
  }, [page, filters]);

  useEffect(() => {
    if (filters && view === "invite-request" && page) {
      dispatch(
        getAllMember({
          filters: filters,
          skip: 1000 * (pagenumber - 1),
          limit: 1000,
        })
      );
    }
  }, [page, filters, reminderResponse, cancelInviteData]);

  useEffect(() => {
    if (getInviteMemberdata && Array.isArray(getInviteMemberdata)) {
      const data1: any = getInviteMemberdata?.map((member: any) => ({
        key: member?.id,
        _id: member?.id,
        name: `${member?.first_name} ${member?.last_name}`,
        email: member?.email,
        roles: member?.roles,
        role: member?.roles?.toString(),
        status: member?.signup_completed
          ? "Signed In"
          : member?.invitation_expiry_time >
            Date.parse(new Date()?.toISOString()) / 1000
            ? "Pending"
            : "Expired",
        invitedOn: member?.createdAt,
        designation: member?.designation,
        display_picture: member?.display_picture,
      }));
      setRowsInvite(data1);
      setRowsAllInvite(data1);
      setLoading(false);
    }
  }, [getInviteMemberdata]);

  useEffect(() => {
    if (getAllMemberData) {
      if (!memberData) {
        setLoading(true);
        setMemberData(getAllMemberData);
      }
      setGetInviteMemberData(
        getAllMemberData?.result?.filter(
          (item: any) => item?.email !== userInfo?.email
        )
      );
    }
  }, [getAllMemberData]);

  useEffect(() => {
    if (getMemberData) {
      setGetUserMemberData(getMemberData);
      const data1: any = getMemberData?.result?.map((member: any) => ({
        key: member?.id,
        _id: member?.id,
        name: `${member?.first_name} ${member?.last_name}`,
        email: member?.email,
        display_picture: member?.display_picture,
        licenses: member?.licenses,
        roles: member?.roles,
        role: member?.roles?.toString(),
        designation: member?.designation,
        last_active: member?.last_active,

        last_active_formatted: moment.unix(member?.last_active),
        data: Date.parse(new Date()?.toISOString()) / 1000,
        diff_years: (moment.unix((Date.parse(new Date()?.toISOString()) / 1000))).diff(moment.unix(member?.last_active), "years"),
        diff_months: (moment.unix((Date.parse(new Date()?.toISOString()) / 1000))).diff(moment.unix(member?.last_active), "months"),
        diff_weeks: (moment.unix((Date.parse(new Date()?.toISOString()) / 1000))).diff(moment.unix(member?.last_active), "weeks"),
        diff_days: (moment.unix((Date.parse(new Date()?.toISOString()) / 1000))).diff(moment.unix(member?.last_active), "days"),
        diff_hours: (moment.unix((Date.parse(new Date()?.toISOString()) / 1000))).diff(moment.unix(member?.last_active), "hour"),
        diff_minutes: (moment.unix((Date.parse(new Date()?.toISOString()) / 1000))).diff(moment.unix(member?.last_active), "minutes"),
        diff_seconds: (moment.unix((Date.parse(new Date()?.toISOString()) / 1000))).diff(moment.unix(member?.last_active), "seconds"),


      }));
      setRowsUser(data1);
      setRowsAllUser(data1);
      setLoading(false);
    }
  }, [getMemberData]);

  return (
    <div className="user">
      <div className="user-header">
        <TopHeader />

        {view !== "invite-user" && (
          <BottomHeader
            setFilters={setFilters}
            downloadActive={downloadActive}
            setRowsUser={setRowsUser}
            setRowsInvite={setRowsInvite}
            rowsAllUser={rowsAllUser}
            rowsAllInvite={rowsAllInvite}
            memberData={memberData}
          />
        )}
      </div>
      {view === "invite-user" ? (
        <InviteUsers />
      ) : view === "all-users" ? (
        <Body
          downloadActive={downloadActive}
          setDownloadActive={setDownloadActive}
          getUserMemberdata={getUserMemberdata}
          rows={rowsUser}
          memberData={memberData}
          loading={Loading}
        />
      ) : (
        <InviteRequestTable
          downloadActive={downloadActive}
          setDownloadActive={setDownloadActive}
          getInviteMemberdata={getInviteMemberdata}
          rows={rowsInvite}
          memberData={memberData}
          loading={Loading}
        />
      )}
    </div>
  );
};

export default layoutTwo(User);
