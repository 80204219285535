export const country = [
  "United States",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia (Czech Republic)",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor (Timor-Leste)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia (formerly Macedonia)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const state = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const getStartFaq = [
  {
    question: "How does it work for employers?",

    answer:
      "You can create a employer profile based on minimal platform usage fees, post job openings, and browse candidate profiles. We offer various features like Job board, applicant tracking, applicant communications, additional features such as AI based candidate screening, Employer branding and marketing can be availed at additional cost.",
  },
  {
    question: "Is there a cost to post jobs?",

    answer:
      "Basic job postings are included in platform usage fees which is Nominal. We offer premium credit based plans with additional features like boosted visibility and candidate matching for a fee.",
  },
  {
    question: "How do I find the best candidates?",

    answer:
      "Utilize our search filters based on skills, experience, and location. We also offer a matching system that recommends qualified candidates based on your job postings it’s a AI based feature and costs extra.",
  },
  {
    question: "Can I screen candidates before contacting them?",

    answer:
      "Yes, you can review resumes, cover letters, and candidate profiles before initiating contact.",
  },
];

export const productAndService = [
  {
    question: "What are the core features of your platform?",

    answer:
      "Our platform offers a suite of recruitment tools, including job posting, applicant tracking, and applicant communication. These features are included in our basic platform usage fee.",
  },
  {
    question: "Do you offer any additional services?",

    answer:
      "Yes! We offer additional AI-powered services to enhance your recruitment process. These include: * AI Resume Shortlisting: This service uses advanced algorithms to screen resumes based on your specific criteria, saving you time and effort. * AI Employer Branding & Marketing: This service helps you craft a compelling employer brand and target the right candidates through targeted advertising.",
  },
  {
    question: "What does the basic platform usage fee cover?",

    answer:
      "The basic platform usage fee covers: * Posting unlimited jobs * Tracking applicants through all stages of the recruitment process * Communicating with applicants directly through the platform",
  },
  {
    question: "Is there a limit on the number of applicants I can track?",

    answer:
      "No, there is no limit on the number of applicants you can track with the basic platform usage fee.",
  },

  {
    question: "How much do the AI-powered services cost?",

    answer:
      "The cost of our AI-powered services varies depending on your specific needs. We offer a variety of credit based pricing plans to fit your budget.",
  },

  {
    question: "Is there a limit on the number of applicants I can track?",

    answer:
      "No, there is no limit on the number of applicants you can track with the basic platform usage fee.",
  },

  {
    question:
      "Do I need to subscribe to the basic platform to use the AI-powered services?",

    answer:
      "Yes, you will need to subscribe to the basic platform to use our AI-powered services.",
  },

  {
    question: "Do you offer a free trial?",

    answer:
      "Yes, we offer a free trial of our platform so you can see how it can benefit your recruitment process.",
  },

  {
    question: "How do I get started?",

    answer:
      "To get started, simply create a free account on our platform. You can then explore the features and pricing options to find the perfect solution for your needs.",
  },

  {
    question: "Do you have any customer support available?",

    answer:
      "Yes, we offer comprehensive customer support to help you get the most out of our platform. You can reach us by email, phone, or live chat.",
  },
];

export const billingAndPayment = [
  {
    question: "What are the basic platform usage fees?",

    answer:
      "Our basic plan includes job posting, Applicant Tracking System (ATS), and applicant communication tools. The specific fee depends on your chosen plan and subscription length. You can find details on our pricing page.",
  },
  {
    question: "Do I get charged for unsuccessful hires?",

    answer:
      "No, our platform usage fees are not tied to successful hires. You are charged for using the platform features, regardless of the outcome of your recruitment efforts.",
  },
  {
    question: "What are credits?",

    answer:
      "Credits are a virtual currency you can use to purchase additional services on our platform, such as AI-powered resume shortlisting and employer branding & marketing tools",
  },
  {
    question: "How do I purchase credits?",

    answer:
      "You can purchase credits in various packages directly within your account dashboard",
  },

  {
    question: "Can I use unused credits in the next billing cycle?",

    answer:
      "This depends on your chosen credit package. Some packages may have expiration dates for unused credits. Please refer to the specific details when purchasing credits.",
  },
  {
    question: "What is a Lot.",

    answer: "A lot is a unit to represent credits, 1Lot = 1000 credits",
  },
  {
    question: "What payment methods do you accept?",

    answer: "We accept most major credit cards and some debit cards.",
  },
  {
    question: "When will I be charged?",

    answer:
      "You will be charged at the beginning of your chosen billing cycle (monthly, annually, etc.).",
  },
  {
    question: "Can I see past invoices?",

    answer:
      "Yes, you can access and download your past invoices directly within your account dashboard.",
  },
  {
    question: "How can I update my billing information?",

    answer:
      "You can update your billing information within your account settings.",
  },
  {
    question: "How much do AI resume shortlisting credits cost?",

    answer:
      "The cost per credit for AI resume shortlisting varies depending on the volume of resumes you process. Please refer to our pricing page for details.",
  },
  {
    question: "How much do AI employer branding & marketing credits cost?",

    answer:
      "The cost per credit for AI employer branding & marketing tools varies depending on the specific service you choose. Please refer to our pricing page for details.",
  },
  {
    question: "Is there a minimum credit purchase for AI services?",

    answer:
      "Yes, there may be a minimum credit purchase required to access AI services. Please refer to our pricing page for details.",
  },

  {
    question: "How do I purchase credits?",

    answer:
      "You can purchase credits in various packages directly within your account dashboard",
  },
];

export const accountSection = [
  {
    question: "How can I update my account information?",

    answer:
      "You can update your account information by logging in and going to the My Profile section. Here, you can edit your contact details, company information, and notification preferences",
  },
  {
    question: "How can I change my password?",

    answer:
      "You can change your password by going to the Security Settings section under My Profile.",
  },
  {
    question: "How can I close my account?",

    answer:
      "We understand that there might be times you want to take a break. To close your account, please contact our support team at Support@onefinnet.com . Please note: There might be outstanding charges on your account that need to be settled before closure.",
  },
  {
    question: "What are the platform usage fees?",

    answer:
      "Our platform offers a basic level of service that includes job posting, applicant tracking, and communication tools. There is a monthly/annual subscription fee associated with this access. You can find a detailed breakdown of our pricing plans on our [pricing page](link to pricing page).",
  },

  {
    question: "How do I pay for my platform usage?",

    answer:
      "We offer several secure payment options, including credit card and debit card. You can choose your preferred method during the subscription process.",
  },

  {
    question: "What are credits?",

    answer:
      "Credits are a virtual currency you can purchase within the platform. These credits allow you to unlock additional features like AI-powered resume shortlisting and employer branding & marketing services",
  },
  {
    question: "How can I purchase credits?",

    answer:
      "You can purchase credits directly from your My Account dashboard. We offer various credit packages to suit your needs.",
  },

  {
    question: "How much do AI-powered features cost?",

    answer:
      "The cost of AI features varies depending on the specific service and the number of credits required. You'll find the credit cost displayed for each service within the platform.",
  },
  {
    question: "Can I use my platform usage fee to purchase credits?",

    answer:
      "No, platform usage fees and credits are separate. Credits are for optional, add-on features, while the platform fee covers basic functionalities.",
  },

  {
    question: "How can I track my credit usage?",

    answer:
      "You can track your credit usage and transaction history within your My Account dashboard. This section will display your current credit balance and details of past purchases",
  },
  {
    question: "My credits are expiring soon. Can I extend their validity?",

    answer:
      "Currently, we don't offer credit extensions. However, you can always top up your account with fresh credits before your existing ones expire.",
  },

  {
    question:
      "I accidentally purchased the wrong credit package. Can I get a refund?",

    answer:
      "We offer refunds on unused credits under specific circumstances. Please contact our support team at Support@onefinnet.com for more information on our refund policy.",
  },
];

export const legalAndCopyright = [
  {
    question: "Who owns the content I post?",

    answer:
      "You retain ownership of all content you post on our platform, including job postings, resumes, and company descriptions.",
  },
  {
    question: "Can I remove my content?",

    answer: "Yes, you can remove your content at any time.",
  },
  {
    question: "What about copyright infringement?",

    answer:
      "We take copyright infringement seriously. If you believe someone has infringed on your copyright, please contact our support team.",
  },
  {
    question: "What does the base platform fee cover?",

    answer:
      "The base platform fee covers features like job posting, applicant tracking, and basic applicant communication tools.",
  },

  {
    question: "What are the additional credit-based services?",

    answer:
      "We offer additional credit-based services such as AI-powered resume shortlisting and AI-enabled employer branding and marketing tools.",
  },

  {
    question: "How do I purchase credits?",

    answer:
      "You can purchase credits through our secure payment gateway within the platform.",
  },
  {
    question: "How is my data protected?",

    answer:
      "We take data security very seriously and have implemented industry-standard security measures to protect user data. For more information, please refer to our Privacy Policy.",
  },
  {
    question: "Who can access my data?",

    answer:
      "Your data will only be accessed by authorized personnel and for legitimate business purposes. We will never share your data with third parties without your consent.",
  },

  {
    question: "Where can I find your Terms of Service?",

    answer: "You can find our Terms of Service on our website.",
  },

  {
    question: "Do you offer legal advice?",

    answer:
      "No, we do not offer legal advice. If you have any legal questions, please consult with an attorney.",
  },
  {
    question: "How can I contact you with further questions?",

    answer:
      "You can contact our support team through our website or by email at Support@onefinnet.com .",
  },
];

export function Redirect(BASE: any) {
  if (
    BASE === "https://api.onefinnet.com" ||
    BASE === "https://api.elevatecareernetwork.com"
  ) {
    return (window.location.href = "https://talent.onefinnet.com/jobs");
  } else if (BASE === "https://api.test.ecndev.io") {
    return (window.location.href = "https://talent.test.ecndev.io/jobs");
  } else {
    return (window.location.href = "https://talent.dev.ecndev.io/jobs");
  }
}

export const recruitRedirect = (BASE: any) => {
  if (
    BASE === "https://api.dev.ecndev.io"
  )
    return "https://talent.dev.ecndev.io/";
  else if (BASE === "https://api.test.ecndev.io")
    return "https://talent.test.ecndev.io/";
  else if (BASE === "https://api.onefinnet.com")
    return "https://talent.onefinnet.com/";
}


export const loginToAuth = (BASE: any) => {
  if (
    BASE === "https://api.dev.ecndev.io"
  )
    return "https://auth.dev.ecndev.io/signin?origin=https://talent.dev.ecndev.io/dashboard";
  else if (BASE === "https://api.test.ecndev.io")
    return "https://auth.test.ecndev.io/signin?origin=https://talent.dev.ecndev.io/dashboard";
  else if (BASE === "https://api.onefinnet.com")
    return "https://auth.onefinnet.com/signin?origin=https://talent.onefinnet.com/dashboard";
}


export const googleAndMicrosoftRedirect = (BASE: any) => {
  if (
    BASE === "https://api.dev.ecndev.io"
  )
    return "https://api.dev.ecndev.io";
  else if (BASE === "https://api.test.ecndev.io")
    return "https://api.test.ecndev.io";
  else if (BASE === "https://api.onefinnet.com")
    return "https://api.onefinnet.com";
}

export const signUpAuth = (BASE: any) => {
  if (
    BASE === "https://api.dev.ecndev.io"
  )
    return "https://auth.dev.ecndev.io/signup";
  else if (BASE === "https://api.test.ecndev.io")
    return "https://auth.test.ecndev.io/signup";
  else if (BASE === "https://api.onefinnet.com")
    return "https://auth.onefinnet.com/signup";
}
